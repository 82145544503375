html,
body {
  height: 100%;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: calc(100vh - 50px);
}

@import '~@fortawesome/fontawesome-free/css/all.min.css';

input[type='file'] {
  display: none;
}

[id^='react-select'] {
  box-shadow: none !important;
}
