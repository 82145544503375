@tailwind base;

@layer base {
  html {
    margin-left: calc(100vw - 100%) !important;
    padding-right: 0 !important;
  }

  img {
    @apply inline;
  }

  svg {
    @apply inline;
  }

  button:focus {
    outline: none;
    outline: none;
  }
}

@tailwind components;
@tailwind utilities;
@tailwind screens;
